import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Correct import for Swiper 11.1.9
import 'swiper/css';
import 'swiper/css/pagination';
import './Stayle.css'; // Custom CSS file for additional styling

const testimonials = [
  {
    role: 'CEO',
    name: 'Habteyesus Grama',
    text: 'This is a testimonial from the CEO. It highlights the amazing work done by the team.',
    image: '/images/habte.jpg', // Path relative to the public folder
  },
  {
    role: 'Manager',
    name: 'Hamdala Tamrat',
    text: 'Our manager’s testimonial goes here, showcasing the impactful results achieved.',
    image: '/images/hamdal.jpg', // Path relative to the public folder
  },
  {
    role: 'Full Stack web developer',
    name: 'Wbishet Tereffe',
    text: 'As the Lead Developer, I’m thrilled with the progress and outcomes achieved by our project.',
    image: '/images/wbshet.jpg', // Path relative to the public folder
  },
];

const Testimony = () => {
  return (
    <div className='pt-4 pb-12' id="testimonials">
      <div className='text-2xl text-black font-bold font-mono mb-4 underline-red'>
        Testimonial
      </div>
      <Swiper
        modules={[Pagination]} // Pass the Pagination module here
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView={1}
        className="testimonial-swiper"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div
              className='
                w-9/12 h-72 shadow-md bg-white rounded-md mx-auto p-4
                transition-transform duration-500 ease-in-out transform hover:scale-105
                flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4
              '
            >
              <img src={testimonial.image} alt={testimonial.name} className='w-20 h-20 rounded-full object-cover' />
              <div className='flex-1 text-center sm:text-left'>
                <h3 className='text-xl font-semibold'>{testimonial.name}</h3>
                <p className='text-gray-500'>{testimonial.role}</p>
                <p className='mt-2'>{testimonial.text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimony;
