import React from 'react'
import Header from './Header'
import Testimony from './testimonials'
import Teuch from './Teuch'
import Feuter from './about'
import Expert from './Expert'
import Portfolio from './Portfolio'
import SkillsSection from './SkillsSection'

function Home() {
  return (
    <div className='' id='home'>
        <Header />
        <Feuter />
        <Expert />
        <Portfolio />
        <SkillsSection />
        <Testimony />
        <Teuch />
      
    </div>
  )
}

export default Home
