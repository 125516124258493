import React from 'react';
import { FaPhone } from 'react-icons/fa';

function Header() {
  const handleCallClick = () => {
    const phoneNumber = "+251909491858"; // Replace with the actual phone number
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div>
      <nav className="bg-white fixed w-full z-20 top-0 left-0 border-b border-gray-200 shadow-md">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse"> 
            <span className="self-center text-2xl font-bold whitespace-nowrap">
              Ersamo
            </span>
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-4 rtl:space-x-reverse">
            <button
              type="button"
              onClick={handleCallClick}
              className="text-black from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center shadow-lg flex items-center space-x-2"
            >
              <span>Call Me</span>
              <FaPhone className="text-lg" />
            </button>
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
              >
                <path d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
          <div
            className="items-center justify-start hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li className="group relative ml-6">
                <a href="#home" className="flex flex-col items-center py-2 px-4 text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>Home</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
              <li className="group relative ml-12">
                <a href="#about" className="flex flex-col items-center py-2 px-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>About</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
              <li className="group relative ml-12">
                <a href="#portfolio" className="flex flex-col items-center py-2 px-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>Portfolio</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
              <li className="group relative ml-12">
                <a href="#Skills" className="flex flex-col items-center py-2 px-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>Skills</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
              <li className="group relative ml-12">
                <a href="#testimonials" className="flex flex-col items-center py-2 px-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>Testimonials</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
              <li className="group relative ml-12">
                <a href="#contact" className="flex flex-col items-center py-2 px-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                  <div className='text-md'>Contact</div>
                  <span className="block mt-1 w-0 h-0.5 bg-blue-700 transition-all duration-300 group-hover:w-full"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
