import React from 'react';
import { motion } from 'framer-motion';

function AboutMe() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const slideIn = {
    initial: { x: '-100vw' },
    animate: { x: 0 },
  };

  return (
    <section className=" " id="about">
      <div className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center max-w-screen-xl mx-auto">
          <motion.div
            className="w-full sm:w-1/2 p-10"
            initial="initial"
            animate="animate"
            variants={slideIn}
            transition={{ duration: 0.8 }}
          >
            <div className="text-center">
              <img
                src="/images/image.png" // Replace with your personal image path
                alt="Profile"
                className="w-full max-w-xs h-auto mx-auto rounded-lg shadow-lg object-cover" // Rectangular shape with rounded corners
              />
            </div>
          </motion.div>
          <motion.div
            className="w-full sm:w-1/2 p-5"
            initial="initial"
            animate="animate"
            variants={fadeInUp}
            transition={{ duration: 0.8 }}
          >
            <div className="text bg-white rounded-md p-4">
              <span className="text-gray-500 border-b-2  border-indigo-600 uppercase text-sm font-semibold">
                About Me
              </span>
              <h2 className="my-4 font-bold text-3xl sm:text-4xl">
                Hello, I'm <span className="text-indigo-600">Ersamo Dentamo</span>
              </h2>
              <p className="text-gray-700 leading-relaxed text-left mb-4">
                Hi there! I'm Ersamo Dentamo, a passionate web developer with expertise in creating dynamic and responsive websites. My journey in web development started with a fascination for technology and design, leading me to develop a range of skills in front-end and back-end technologies.
                <br /><br />
                With experience in React, Tailwind CSS, and modern web practices, I strive to build user-friendly and visually appealing web applications. I enjoy tackling new challenges and continually learning to stay ahead in the ever-evolving tech landscape.
                <br /><br />
                When I'm not coding, you can find me reading book, playing chess. Feel free to reach out if you'd like to collaborate or just have a chat about tech!
              </p>
              <a
                href="/Resume/Resume.pdf" // Replace with your actual CV file path
                download
                className="inline-block px-6 py-3 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg shadow-md transition duration-300"
              > 
                Download CV
              </a>
              <div className="mt-6 flex gap-4 justify-center">
                <a
                                   href="mailto:dentamoersamo@gmail.com" // Updated with your email address

                  className="text-red-500 hover:underline flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                 <div className='ml-2 text-indigo-600 '>Email Me</div> 
                </a>
                <a
                  href="https://www.linkedin.com/in/ersamo-dentamo-8537a1214/" // Replace with your LinkedIn profile URL
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:underline flex items-center"
                >
<img src="https://tailwindflex.com/public/images/icons/linkedin.png" class="object-cover w-6 h-6  m-auto" title="linkedin"/>                  
      <div className='ml-2 text-indigo-600 '>Linked </div> 
                </a>
                <a
                  href="https://github.com/dentamo" // Replace with your GitHub profile URL
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black hover:underline flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                    <div className='ml-2 text-indigo-600 '>GitHub</div> 
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      </div>
    </section>
  );
}

export default AboutMe;
