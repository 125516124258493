import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaFacebookF, FaLinkedinIn, FaTelegramPlane, FaWhatsapp, FaEnvelope, FaPhone, FaAddressBook, FaAddressCard } from 'react-icons/fa'; // Importing icons

function Teuch() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationProps = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section id="contact" ref={ref}>
      <motion.div
        className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={animationProps}
      >
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <p className="text-base font-semibold uppercase tracking-wide text-blue-600">
              Contact
            </p>
            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 sm:text-5xl">
              Get in Touch
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600">
              I Am Ersamo Dentamo 
            </p>
          </div>
        </div>
        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            <div className="h-full pr-6">
              <p className="mt-3 mb-12 text-lg text-gray-600 bg-white rounded-md p-4 shadow-md">
                Do you have a connection with a skilled software engineer? Whether it's a friend, colleague, 
                or someone in your network, they could be the perfect fit for your next project. With expertise 
                in the latest technologies and a passion for solving complex problems.
              </p>
              <motion.div className="flex justify-center" variants={itemVariants}>
          <ul className="mb-6 md:mb-0 bg-white rounded-md p-6 shadow-lg w-full max-w-lg">
            <li className="flex mb-6">
              <div className="flex h-12 w-12 items-center justify-center rounded bg-blue-100">
                <FaAddressCard className="text-blue-500 text-xl" />
              </div>
              <div className="ml-8">
                <h3 className="text-lg font-medium text-gray-900">My Address</h3>
                <p className="text-gray-600">Addis Abeba, Ethiopia</p>
              </div>
            </li>
            <li className="flex mb-6">
              <div className="flex h-12 w-12 items-center justify-center rounded bg-blue-100">
                <FaPhone className="text-blue-500 text-xl" />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-gray-900">Contact</h3>
                <p className="text-gray-600">Mobile: +251909491858</p>
              </div>
            </li>
            <li className="flex mb-6">
              <div className="flex h-12 w-12 items-center justify-center rounded bg-blue-100">
                <FaEnvelope className="text-red-500 text-xl" />
              </div>
              <div className="">
                <h3 className="text-lg font-medium text-gray-900">Email</h3>
                <p className="text-gray-600">dentamoersamo@gmail.com</p>
              </div>
            </li>
           
          </ul>
        </motion.div>
            </div>
            <div className="card h-fit max-w-6xl p-5 md:p-12 bg-white rounded-md p-4 shadow-md" id="form">
              <h2 className="mb-4 text-2xl font-bold">
                Ready to Get Started?
              </h2>
              <form id="contactForm">
                <div className="mb-6">
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="name"
                      className="pb-1 text-xs uppercase tracking-wider"
                    />
                    <input
                      type="text"
                      id="name"
                      autoComplete="given-name"
                      placeholder="Your name"
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                      name="name"
                    />
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="email"
                      className="pb-1 text-xs uppercase tracking-wider"
                    />
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Your email address"
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                      name="email"
                    />
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="textarea"
                      className="pb-1 text-xs uppercase tracking-wider"
                    />
                    <textarea
                      id="textarea"
                      name="textarea"
                      cols={30}
                      rows={5}
                      placeholder="Write your message..."
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-gray-900 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 dark:bg-white dark:text-gray-800 dark:hover:bg-gray-300"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M12 20v-6m0 0l-4 4m4-4l4 4" />
                    </svg>
                  </span>
                  Send
                </button>
              </form>
              <div className="flex justify-center space-x-4 mt-6">
                <a href="mailto:dentamoersamo@gmail.com" target="_blank" rel="noopener noreferrer">
                  <FaEnvelope className="text-red-500 mr-2 hover:text-blue-600 text-2xl" />
                </a>
                <a href="https://facebook.com/ersamo.dentamo.3/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="text-blue-600 ml-2 hover:text-blue-600 text-2xl" />
                </a>
                <a href="https://www.linkedin.com/in/ersamo-dentamo-8537a1214/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn className="text-blue-600 ml-4 hover:text-blue-600 text-2xl" />
                </a>
                <a href="https://t.me/dentamo" target="_blank" rel="noopener noreferrer">
                  <FaTelegramPlane className="text-blue-600 ml-4 hover:text-blue-600 text-2xl" />
                </a>
                <a href="https://wa.me/251909491858" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp className="text-green-600 ml-4 hover:text-green-600 text-2xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default Teuch;
