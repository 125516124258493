import React, { useState } from "react";
import { motion } from "framer-motion"; // Import Framer Motion for animations

const Portfolio = () => {
  const [showCard, setShowCard] = useState("all");
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const handleProject = (category) => {
    setShowCard(category);
  };

  const handleViewDetails = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleGitHubClick = () => {
    if (selectedProject && selectedProject.githubLink) {
      window.open(selectedProject.githubLink, "_blank");
    }
  };

  const handleDescriptionClick = () => {
    setShowDescription(true);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowDescription(false);
  };

  return (
    <>
      <section className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark" id="portfolio">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <motion.div 
                className="mx-auto mb-[60px] max-w-[510px] text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <span className="text-primary mb-2 block text-lg font-semibold">
                  My Portfolio
                </span>
                <h2 className="text-dark mb-3 text-3xl leading-[1.208] font-bold sm:text-4xl md:text-[40px]">
                  My Recent Projects
                </h2>
                <p className="text-body-color text-base dark:text-dark-6">
                  Explore our latest projects that showcase our commitment to excellence in web development and design.
                </p>
              </motion.div>
            </div>
          </div>

          <div className="w-full flex flex-wrap justify-center -mx-4">
            <div className="w-full px-4">
              <ul className="flex flex-wrap justify-center mb-12 space-x-1">
                {["all", "Website", "design"].map((category) => (
                  <li className="mb-1" key={category}>
                    <button
                      onClick={() => handleProject(category)}
                      className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                        showCard === category
                          ? "bg-primary text-black shadow-lg"
                          : "text-body-color dark:text-dark-6 hover:bg-primary hover:text-black hover:font-bold"
                      }`}
                    >
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            {[
              { category: "Website", image: "https://www.fleetroot.com/wp-content/uploads/2020/01/Beacon_card-based_tracking_systeam-1.jpg", title: "Student Follow-Up", description: "Effective student follow-up tracks progress and identifies improvement areas, fostering stronger student-teacher relationships and supporting success.", githubLink: "https://github.com/printAsmamaw/Student-FollowUP" },
              { category: "Website", image: "https://cdn.prod.website-files.com/5db3a833c38811bc8a40f5be/63c3018e4553f6771cbed52b_PIQ_Frame_Screens.jpg", title: "online exam system", description: "An online exam system enables remote testing with automated grading, real-time monitoring, and secure submissions, streamlining the assessment process for educators and students.", githubLink: "https://github.com/hamdegithub/ProjectTwo" },
              { category: "Website", image: "https://www.xypherlabs.com/assets/custom/images/background/learning-management-system-concept.webp", title: "course material management system", description: "A course material management system organizes and distributes educational resources, providing easy access to lectures and assignments while streamlining content updates and tracking student engagement.", githubLink: "https://github.com/Habteyesus/CRMMS.git" },
              { category: "Design", image: "https://img.freepik.com/premium-vector/delivery-service-design-white-background-banner_145865-146.jpg", title: "UI/UX Design", description: "A delivery app web design emphasizes a user-friendly interface for easy ordering and tracking. Key features include intuitive navigation, real-time updates, and responsive design for a seamless experience across devices.", githubLink: "https://github.com/ui-ux-design" },
            ].map((project, index) => (
              <PortfolioCard
                key={index}
                ImageHref={project.image}
                category={project.category}
                title={project.title}
                button="View Details"
                onClick={() => handleViewDetails(project)}
                showCard={showCard}
              />
            ))}
          </div>
        </div>
      </section>

      {showModal && (
        <Modal
          title={selectedProject.title}
          onClose={closeModal}
          onGitHubClick={handleGitHubClick}
          onDescriptionClick={handleDescriptionClick}
        />
      )}

      {showDescription && (
        <DescriptionModal
          title={selectedProject.title}
          description={selectedProject.description}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default Portfolio;

const PortfolioCard = ({ showCard, category, ImageHref, title, button, onClick }) => {
  return (
    <>
      <motion.div
        className={`w-full px-4 md:w-1/3 xl:w-1/4 ${
          showCard === "all" || showCard.toLowerCase() === category.toLowerCase() ? "block" : "hidden"
        }`}
        initial={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="relative mb-8">
          <motion.div 
            className="overflow-hidden rounded-[10px] transition-transform duration-500 hover:scale-105"
            whileHover={{ scale: 1.05 }}
          >
            <img src={ImageHref} alt="portfolio" className="w-full object-cover h-40 md:h-32 xl:h-28" />
          </motion.div>
          <motion.div
            className="relative z-10 mx-4 -mt-12 rounded-lg bg-white dark:bg-dark-2 h-32 py-1 px-4 text-center shadow-portfolio dark:shadow-box-dark"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <span className="text-primary mb-1 block text-sm font-medium">{category}</span>
            <h3 className="text-dark  mb-1 text-lg font-semibold">{title}</h3>
            <button
              onClick={onClick}
              className="text-body-color dark:text-dark-6 hover:border-primary hover:bg-primary inline-block rounded-md border border-stroke dark:border-dark-3 py-2 px-4 text-sm font-medium transition hover:text-black hover:font-bold"
            >
              {button}
            </button>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

const Modal = ({ title, onClose, onGitHubClick, onDescriptionClick }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-dark-2 rounded-lg p-8 text-center shadow-lg">
        <h3 className="text-dark  mb-4 text-xl font-semibold">{title}</h3>
        <div className="flex justify-around">
          <button
            onClick={onGitHubClick}
            className="text-black bg-blue-100 hover:bg-blue-200 mr-2 pr-2 mx-auto w-4/6 rounded-md py-2 px-4"
          >
           <svg  viewBox="0 0 1792 1792" className="w-12 h-12 mx-auto" xmlns="http://www.w3.org/2000/svg"><path d="M1664 896q0 251-146.5 451.5t-378.5 277.5q-27 5-39.5-7t-12.5-30v-211q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-121-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-86-13.5q-44 113-7 204-79 85-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-40 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 89t.5 54q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/></svg>
          </button>
          <button
            onClick={onDescriptionClick}
            className="text-black font-samibold bg-green-100 hover:bg-green-200 rounded-md py-2 px-4  w-48 mx-auto"
          >
            <div className="mx-auto ml-8">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto ml-8" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
            </div>
          </button>
        </div>
        <button
          onClick={onClose}
          className="text-body-color dark:text-dark-6 mt-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};
const DescriptionModal = ({ title, description, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-dark-2 rounded-lg p-8 text-center shadow-lg">
        <h3 className="text-dark mb-4 text-xl font-semibold">{title}</h3>
        <p className="text-body-color dark:text-dark-6 mb-4">{description}</p>
        <button
          onClick={onClose}
          className="text-body-color dark:text-dark-6"
        >
          Close
        </button>
      </div>
    </div>
  );
};
