import logo from './logo.svg';
import './App.css';
import Home from './page/Home'
import CustomCursor from './CustomCursor'

function App() {
  return (
    <div className="App bg-gray-100">
      <CustomCursor/>
     <Home />
    </div>
  );
}

export default App;
