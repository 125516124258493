import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJs, faReact, faNodeJs, faCss3Alt, faPython } from '@fortawesome/free-brands-svg-icons';
import { faBrain, faComments, faUsers, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';

const SkillsSection = () => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  const hardSkills = [
    { name: 'JavaScript', level: 'Advanced', percentage: 90, icon: <FontAwesomeIcon icon={faJs} className="text-yellow-500" /> },
    { name: 'React', level: 'Advanced', percentage: 85, icon: <FontAwesomeIcon icon={faReact} className="text-blue-500" /> },
    { name: 'Node.js', level: 'Intermediate', percentage: 75, icon: <FontAwesomeIcon icon={faNodeJs} className="text-green-500" /> },
    { name: 'Tailwind CSS', level: 'Advanced', percentage: 80, icon: <FontAwesomeIcon icon={faCss3Alt} className="text-teal-500" /> },
    { name: 'Python', level: 'Intermediate', percentage: 70, icon: <FontAwesomeIcon icon={faPython} className="text-blue-600" /> },
    { name: 'MySQL', level: 'Intermediate', percentage: 75, icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="text-blue-800" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg> },
  ];

  const softSkills = [
    { name: 'Problem-solving', level: 'Advanced', percentage: 90, icon: <FontAwesomeIcon icon={faBrain} className="text-red-500" /> },
    { name: 'Communication', level: 'Advanced', percentage: 85, icon: <FontAwesomeIcon icon={faComments} className="text-purple-500" /> },
    { name: 'Teamwork', level: 'Advanced', percentage: 80, icon: <FontAwesomeIcon icon={faUsers} className="text-yellow-500" /> },
    { name: 'Leadership', level: 'Intermediate', percentage: 75, icon: <FontAwesomeIcon icon={faChalkboardTeacher} className="text-orange-500" /> },
  ];

  const getProgressBarColor = (percentage) => {
    if (percentage >= 75) return 'bg-green-500';
    if (percentage >= 50) return 'bg-yellow-500';
    return 'bg-blue-500'; // Default color for percentages below 50
  };

  const renderSkill = (skill, index) => (
    <motion.div
      key={index}
      className="flex items-center mb-4"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="text-2xl mr-3">{skill.icon}</div>
      <div className="flex-1">
        <h4 className="text-lg font-medium text-gray-700">{skill.name}</h4>
        <div className="w-full bg-gray-200 rounded-full h-3">
          <motion.div
            className={`h-3 rounded-full ${getProgressBarColor(skill.percentage)}`}
            style={{ width: inView ? `${skill.percentage}%` : '0%' }}
            initial={{ width: '0%' }}
            animate={{ width: inView ? `${skill.percentage}%` : '0%' }}
            transition={{ duration: 1 }}
          ></motion.div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <section id='Skills' className="max-w-4xl mx-auto py-12" ref={ref}>
      <motion.h2 
        className="text-3xl font-extrabold text-center text-gray-800 mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Skills
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <h3 className="text-2xl font-bold text-gray-700 mb-4">Hard Skills</h3>
          <div>{hardSkills.map(renderSkill)}</div>
        </motion.div>
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h3 className="text-2xl font-bold text-gray-700 mb-4">Soft Skills</h3>
          <div>{softSkills.map(renderSkill)}</div>
        </motion.div>
      </div>
    </section>
  );
};

export default SkillsSection;
