import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function CustomCursor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [trail, setTrail] = useState([]);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mouseenter', onMouseEnter);
      document.addEventListener('mouseleave', onMouseLeave);
    };

    const removeEventListeners = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseenter', onMouseEnter);
      document.removeEventListener('mouseleave', onMouseLeave);
    };

    const onMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
      setTrail((prev) => [...prev, { x: e.clientX, y: e.clientY }].slice(-5));
    };

    const onMouseDown = () => {
      setClicked(true);
      setTimeout(() => setClicked(false), 300);
    };

    const onMouseUp = () => {
      setClicked(false);
    };

    const onMouseEnter = () => {
      setHidden(false);
    };

    const onMouseLeave = () => {
      setHidden(true);
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  useEffect(() => {
    const handleHoverEvents = () => {
      document.querySelectorAll('a, button').forEach((el) => {
        el.addEventListener('mouseenter', () => setHovered(true));
        el.addEventListener('mouseleave', () => setHovered(false));
      });
    };

    handleHoverEvents();
  }, []);

  const cursorVariants = {
    default: {
      x: position.x - 16,
      y: position.y - 16,
      scale: 1,
      backgroundColor: 'linear-gradient(45deg, #6366F1, #E0E7FF)',
      borderColor: '#6366F1',
      mixBlendMode: 'difference',
      boxShadow: '0 0 10px rgba(99, 102, 241, 0.5)',
    },
    clicked: {
      scale: 1.2,
      backgroundColor: 'linear-gradient(45deg, #4F46E5, #A5B4FC)',
      borderColor: '#4F46E5',
      transition: { duration: 0.3, ease: 'easeOut' },
    },
    hovered: {
      scale: 1.5,
      backgroundColor: 'linear-gradient(45deg, #A5B4FC, #E0E7FF)',
      borderColor: '#6366F1',
      mixBlendMode: 'difference',
    },
    hidden: {
      opacity: 0,
    },
  };

  const rippleVariants = {
    initial: { scale: 0, opacity: 0.7 },
    animate: { scale: 2, opacity: 0 },
  };

  return (
    <>
      <AnimatePresence>
        {trail.map((point, index) => (
          <motion.div
            key={index}
            className="fixed top-0 left-0 w-2 h-2 rounded-full bg-indigo-500 pointer-events-none z-40"
            style={{
              x: point.x - 4,
              y: point.y - 4,
              opacity: (index + 1) / 5,
            }}
            transition={{ duration: 0.1 }}
          />
        ))}
        <motion.div
          className="fixed top-0 left-0 w-8 h-8 rounded-full border-2 pointer-events-none z-50"
          variants={cursorVariants}
          animate={hidden ? 'hidden' : clicked ? 'clicked' : hovered ? 'hovered' : 'default'}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        />
      </AnimatePresence>
      {clicked && (
        <motion.div
          className="fixed top-0 left-0 w-8 h-8 rounded-full bg-indigo-400 pointer-events-none z-50"
          style={{
            x: position.x - 16,
            y: position.y - 16,
          }}
          initial="initial"
          animate="animate"
          variants={rippleVariants}
          transition={{ duration: 0.5 }}
        />
      )}
    </>
  );
}
export default CustomCursor;